import React, { useState, useEffect } from "react";
import {
  Editor,
  EditorState,
  convertFromRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";

const HelpSideBarSelected = ({ selectedSection }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [hasContent, setHasContent] = useState(false);

  useEffect(() => {
    if (selectedSection && selectedSection.Content) {
      let contentState;
      try {
        const rawContent = JSON.parse(selectedSection?.Content);
        contentState = convertFromRaw(rawContent);
        setHasContent(true);
      } catch (error) {
        const blocksFromHTML = convertFromHTML(selectedSection?.Content);
        if (blocksFromHTML.contentBlocks.length > 0) {
          contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          setHasContent(true);
        } else {
          setHasContent(false);
        }
      }

      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setHasContent(false);
    }
  }, [selectedSection]);

  return (
    <div>
      {hasContent ? (
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          readOnly={true}
        />
      ) : (
        <p>No content available</p>
      )}
    </div>
  );
};

export default HelpSideBarSelected;
