import React, { useEffect, useState } from "react";
import SingleSelectDropDown from "../../common/SingleSelectDropDown";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useNavigate, useParams } from "react-router-dom";
import { globalApiCalling } from "../../../Utils/globalApiCall";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CreateHelp = () => {
  const [articleName, setArticleName] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [articleData, setArticleData] = useState(null);

  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedSection, setSelectedSection] = useState("Category");
  const [options, setOptions] = useState();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const getArticle = async () => {
    try {
      if (id) {
        const response = await globalApiCalling(
          "GET",
          `doublefollowers/v1/help-article/detail?id=${id}`
        );
        const data = await response?.json();
        if (data?.code === 1000 || data?.code == 200) {
          setArticleData(data?.data);
        }
      }
    } catch (error) {
      console.error("Error fetching media:", error);
      toast.error(error?.message);
    }
  };
  const handleArticle = async (e) => {
    e.preventDefault();

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    let htmlContent = draftToHtml(rawContentState);
    htmlContent = htmlContent.replace(/<p><\/p>/g, "<br/>");

    const apiEndpoint = id
      ? `doublefollowers/v1/help-article/update`
      : `doublefollowers/v1/help-article/create`;
    const requestMethod = id ? "PUT" : "POST";
    const payload = id
      ? {
          section_id: articleData.Section.ID,
          title: articleName || articleData?.Title,
          content: htmlContent,
          id,
        }
      : {
          section_id: selectedOption?.value,
          title: articleName,
          content: htmlContent,
        };

    try {
      const response = await globalApiCalling(
        requestMethod,
        apiEndpoint,
        payload
      );
      const data = await response?.json();

      console.log("Updated data::", response);

      if (data?.code === 200 || data?.code === 1000) {
        toast.success(t(`Success!`));
        resetForm();
        navigate("/admin/help");
      }
    } catch (error) {
      console.error(error?.message);
      toast.error(error?.message);
    }
  };

  const resetForm = () => {
    setArticleName("");
    setSelectedSection("");
    setSelectedOption(null);
    setEditorState(EditorState.createEmpty());
  };

  const getSectionData = async (e) => {
    try {
      const response = await globalApiCalling(
        "GET",
        `doublefollowers/v1/help-section/list`
      );
      const data = await response?.json();
      const options = data?.data?.map((item) => ({
        label: item?.Name,
        value: item?.ID,
      }));
      setOptions(options);

      //   if (data?.code === 200 || data?.code === 1000) {
      //     toast.success(t(`Success!`));
      //   }
    } catch (error) {
      console.error(error?.message);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    getSectionData();
    getArticle();
  }, []);

  useEffect(() => {
    if (articleData) {
      let contentState;
      try {
        // Try parsing the content as raw JSON (Draft.js format)
        const rawContent = JSON.parse(articleData?.Content);
        contentState = convertFromRaw(rawContent);
      } catch (error) {
        // If JSON parsing fails, treat the content as HTML
        const blocksFromHTML = convertFromHTML(articleData?.Content);
        contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
      }

      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [articleData]);

  return (
    <div>
      <div className="w-full p-4 bg-white shadow-md rounded-[12px]">
        <h1 className="text-2xl font-bold mb-4">Article Editor</h1>

        {/* Article Name Input */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Article Name</label>

          {/* <input
            type="text"
            name="name"
            class="p-2 border-2 activeInput rounded-[8px] mb-4 w-full "
            placeholder="Enter article name"
            value={articleName ? articleName : articleData?.Title}
            onChange={(e) => setArticleName(e.target.value)}
          /> */}

          <input
            type="text"
            name="name"
            className="p-2 border-2 activeInput rounded-[8px] mb-4 w-full"
            placeholder="Enter article name"
            value={articleName !== "" ? articleName : articleData?.Title || ""}
            onChange={(e) => setArticleName(e.target.value)}
          />
        </div>

        {/* Section Management */}
        <div className="mb-4 flex flex-col md:flex-row md:items-end md:space-x-4 space-y-4 md:space-y-0">
          <SingleSelectDropDown
            label={"Associated Session"}
            Options={options}
            handleOptionClick={handleOptionClick}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedOption={selectedOption}
            defaultSelected={
              articleData ? articleData?.Section?.Name : "Select Session"
            }
          />
        </div>

        {/* Editor */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Content</label>
          <div className="border border-gray-300 rounded-md">
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class p-2"
              toolbarClassName="toolbar-class"
            />
          </div>
        </div>

        {/* Save Button */}
        <div className="flex justify-end">
          <button
            type="button"
            class="mr-2 bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            onClick={() => navigate("/admin/help")}
          >
            Cancel
          </button>
          <button
            onClick={handleArticle}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateHelp;
