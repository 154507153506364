import React from "react";
import logo from "../../Assets/Logo.png";
import sent from "../../Assets/EmailSent.png";
import "./EmailSent.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const EmailSent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { email } = location.state || {};

  return (
    <div className="email-sent">
      <img src={logo} alt="Logo" className="logo" />
      <form className="form">
        <h2>{t("Email Sent")}</h2>
        <img src={sent} alt="email-sent-logo" className="email-sent-logo" />
        <p className="text-1">
          {t("An email has been sent on your email address,")}
        </p>
        <p className="text-2">{email}</p>
        <p className="text-3">
          {t("Follow the directions in the email to reset your password.")}
        </p>
      </form>
      <div className="copyright">
        <p>{t("Copyright © 2024 Smart Message Pioneer | Privacy Policy")}</p>
      </div>
    </div>
  );
};
export default EmailSent;
