import React, { useState, useEffect } from "react";
import SvgComponent from "../../SvgComponent/SvgComponent";
import { toast } from "react-toastify";
import SingleSelectDropDown from "../../common/SingleSelectDropDown";
import { useTranslation } from "react-i18next";
import { globalApiCalling } from "../../../Utils/globalApiCall";

const FAQUpsert = ({
  selectedSection,
  setOpenModal,
  fetchData,
  faqId,
  setFaqId,
  modalTitle,
  sectionTitle,
  categoryOptions,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    question: "",
    answer: "",
  });

  const [errors, setErrors] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (faqId) {
      fetchSingleFAQ();
    }
  }, [faqId]);

  const fetchSingleFAQ = async () => {
    try {
      let url;
      selectedSection === "Q/A"
        ? (url = `doublefollowers/v1/faq/detail?id=${faqId}`)
        : (url = `doublefollowers/v1/faq-category/detail?id=${faqId}`);
      const response = await globalApiCalling("GET", url);
      const data = await response?.json();
      if (data?.code === 200 || data?.code === 1000) {
        setFormData({
          name: data?.data?.Name,
          description: data?.data?.Description || "",
          question: data?.data?.Question || "",
          answer: data?.data?.Answer || "",
        });
        setSelectedOption({
          label: data?.data?.Category?.Name,
          value: data?.data?.Category?.ID,
        });
      }
    } catch (error) {
      console.error(t("Error fetching media"), error);
      toast.error(error?.message);
    }
  };

  const onClose = () => {
    setOpenModal(false);
    setFaqId && setFaqId(null);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Clear the error when an option is selected
    setErrors((prevErrors) => ({ ...prevErrors, category: "" }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Clear the error when the user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (selectedSection === "Category") {
      if (!formData.name) {
        newErrors.name = t("Category name is required");
      }
      if (!formData.description) {
        newErrors.description = t("Category description is required");
      }
    } else {
      if (!formData.question) {
        newErrors.question = t("Question is required");
      }
      if (!formData.answer) {
        newErrors.answer = t("Answer is required");
      }
      if (!selectedOption) {
        newErrors.category = t("Category selection is required");
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      let url;
      let method;
      let body;
      if (faqId) {
        if (selectedSection === "Category") {
          url = `doublefollowers/v1/faq-category/update?id=${faqId}`;
          body = {
            name: formData?.name,
            description: formData?.description,
          };
        } else {
          url = `doublefollowers/v1/faq/update`;
          body = {
            category_id: selectedOption?.value,
            question: formData?.question,
            answer: formData?.answer,
            id: faqId,
          };
        }
        method = "PUT";
      } else {
        if (selectedSection === "Category") {
          url = `doublefollowers/v1/faq-category/create`;
          body = {
            name: formData?.name,
            description: formData?.description,
          };
        } else {
          url = `doublefollowers/v1/faq/create`;
          body = {
            category_id: selectedOption?.value,
            question: formData?.question,
            answer: formData?.answer,
          };
        }
        method = "POST";
      }
      const response = await globalApiCalling(method, url, body);
      const data = await response?.json();
      if (data?.code === 1000 || data?.code === 200) {
        toast.success(t("Success!"));
        fetchData();
        onClose();
        setIsLoading(false);
      } else {
        toast.error(t("Failed!"));
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
      <div
        className={`bg-white rounded-lg shadow-lg w-[650px] ${
          selectedSection === "Q/A" ? "min-h-[450px]" : ""
        } p-6 relative`}
      >
        <button
          className="absolute top-4 right-4 w-[30px] h-[30px] bg-[#F0F3FF] flex items-center justify-center rounded-[23px]"
          onClick={onClose}
        >
          <SvgComponent name={"crossIcon"} />
        </button>
        <h2 className="text-xl font-semibold mb-4">
          {t(`Add ${selectedSection}`)}
        </h2>
        <form onSubmit={handleSubmit}>
          {selectedSection === "Category" && (
            <>
              <div className="flex gap-[20px]">
                <div className="w-full">
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    {t("Category")}
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData?.name}
                    onChange={handleChange}
                    className={`p-2 border-2 activeInput rounded-[8px] mb-4 w-full ${
                      errors.name ? "border-red-500" : ""
                    }`}
                    placeholder={t(`Enter category name`)}
                  />
                  {errors.name && (
                    <div className="text-red-500 -mt-3 mb-2 text-sm">
                      {errors.name}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  {t("Description")}
                </label>
                <textarea
                  name="description"
                  value={formData?.description}
                  onChange={handleChange}
                  className={`p-2 border-2 activeInput rounded-[8px] mb-4 w-full h-[200px] resize-none ${
                    errors.description ? "border-red-500" : ""
                  }`}
                  placeholder={t("Enter category description")}
                />
                {errors.description && (
                  <div className="text-red-500 -mt-3 mb-2 text-sm">
                    {errors.description}
                  </div>
                )}
              </div>
            </>
          )}

          {selectedSection === "Q/A" && (
            <>
              <div className="w-full">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  {t("Question")}
                </label>
                <input
                  type="text"
                  name="question"
                  value={formData?.question}
                  onChange={handleChange}
                  className={`p-2 border-2 activeInput rounded-[8px] mb-4 w-full ${
                    errors.question ? "border-red-500" : ""
                  }`}
                  placeholder={t("Enter question")}
                />
                {errors.question && (
                  <div className="text-red-500 -mt-3 mb-2 text-sm">
                    {errors.question}
                  </div>
                )}
              </div>
              <div className="w-full">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  {t("Answer")}
                </label>
                <textarea
                  name="answer"
                  value={formData?.answer}
                  onChange={handleChange}
                  className={`p-2 border-2 activeInput rounded-[8px] mb-4 w-full h-[200px] resize-none ${
                    errors.answer ? "border-red-500" : ""
                  }`}
                  placeholder={t("Enter answer")}
                />
                {errors.answer && (
                  <div className="text-red-500 -mt-3 mb-2 text-sm">
                    {errors.answer}
                  </div>
                )}
              </div>
              <SingleSelectDropDown
                label={"Associated Category"}
                Options={categoryOptions}
                handleOptionClick={handleOptionClick}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                selectedOption={selectedOption}
                defaultSelected={
                  selectedOption ? selectedOption.label : "Select Category"
                }
              />
              {errors.category && (
                <div className="text-red-500 -mt-3 mb-2 text-sm">
                  {errors.category}
                </div>
              )}
            </>
          )}

          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              {isLoading ? (
                <>
                  <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
                  {t("Processing")}
                </>
              ) : faqId ? (
                t("Edit")
              ) : (
                t("Create")
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FAQUpsert;
