import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { FollowerContextProvider } from "./Utils/Context/Context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <FollowerContextProvider>
      <App />
    </FollowerContextProvider>
  </I18nextProvider>
  // </React.StrictMode>
);
