import React, { useState, useEffect } from "react";

const HelpCenterSidebar = ({
  setSelectedSection,
  selectedSection,
  sectionData,
}) => {
  const [expandedSection, setExpandedSection] = useState("");

  useEffect(() => {
    // Filter sections that have items and ensure the first section with items is selected by default
    const filteredSections = sectionData.filter(
      (section) => section.items && section.items.length > 0
    );

    if (filteredSections.length > 0) {
      setExpandedSection(filteredSections[0].title);
      setSelectedSection(filteredSections[0].items[0]); // Set the first item of the first section
    }
  }, [sectionData, setSelectedSection]);

  const handleSectionClick = (section) => {
    if (section.items && section.items.length > 0) {
      setExpandedSection(
        expandedSection === section.title ? "" : section.title
      );
    } else {
      setSelectedSection(section);
      setExpandedSection("");
    }
  };

  const handleItemClick = (item) => {
    setSelectedSection(item);
  };

  return (
    <div className="w-1/4 bg-white p-4 rounded-lg shadow">
      {sectionData
        .filter((section) => section.items && section.items.length > 0) // Only render sections with items
        .map((section, index) => (
          <div key={index}>
            <div
              className="flex items-center justify-between cursor-pointer py-2"
              onClick={() => handleSectionClick(section)}
            >
              <h3
                className={`font-semibold ${
                  selectedSection?.Title === section.title ||
                  expandedSection === section.title
                    ? "text-[#696CFF]"
                    : "text-[#5a5a5a]"
                }`}
              >
                {section.title}
              </h3>
              {section.items && section.items.length > 0 && (
                <svg
                  className={`w-5 h-5 transform transition-transform duration-200 ${
                    expandedSection === section.title ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </div>

            {expandedSection === section.title && section.items && (
              <div className="pl-4">
                {section.items.length > 0
                  ? section.items.map((item, subIndex) => (
                      <div
                        key={subIndex}
                        className={`cursor-pointer py-2 pl-2 rounded-lg text-sm font-semibold ${
                          selectedSection?.Title === item.Title
                            ? "bg-[#f5f6ff] text-[#696CFF]"
                            : "text-[#999]"
                        }`}
                        onClick={() => handleItemClick(item)}
                      >
                        {item?.Title}
                      </div>
                    ))
                  : "N/A"}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default HelpCenterSidebar;
