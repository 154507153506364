import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/Logo.png";
import "./ResetForm.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { globalApiCalling } from "../../Utils/globalApiCall";

const ResetForm = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    email: "",
    newPassword: "",
    currentPassword: "",
  });
  const [error, setError] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  // Extract the token from the URL
  const token = new URLSearchParams(location.search).get("token");

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setError(""); // Clear error message on input change
  };

  const handleEmailSent = async (e) => {
    e.preventDefault();

    if (formState.newPassword !== formState.currentPassword) {
      setError("That password and confirm password don’t match");
      return;
    }

    try {
      const response = await globalApiCalling(
        "POST"`doublefollowers/v1/reset/password`,
        {
          email: formState.email,
          password: formState.newPassword,
          token: token,
        }
      );
      const data = await response?.json();
      if (data?.code === 200 || data?.code === 1000) {
        toast.success(t("Success!"));
        navigate("/");
      } else {
        console.error(t("Error during password reset"), data?.data);
        toast.error(data?.data || t("Error during password reset"));
      }
    } catch (error) {
      console.error(t("Error during password reset"), error);
      toast.error(error?.message);
    }
  };

  return (
    <div className="reset-form">
      <img src={logo} alt="Logo" className="logo" />
      <form className="form" onSubmit={handleEmailSent}>
        <h2>{t("Reset Password")}</h2>
        <div className="text-1 mb-[40px] mt-[10px]">
          <p>
            {t("Please enter your email address to request a password reset.")}
          </p>
        </div>
        <div className="input-container">
          <label htmlFor="email">{t("Email")}</label>
          <input
            type="email"
            id="email"
            className="input"
            value={formState.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="newPassword">{t("New Password*")}</label>
          <input
            type="password"
            id="newPassword"
            className="input"
            value={formState.newPassword}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="currentPassword">{t("Confirm Password*")}</label>
          <input
            type="password"
            id="currentPassword"
            className="input"
            value={formState.currentPassword}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="reset-button">
          {t("Reset")}
        </button>
        {error && <div className="error-message">{t(error)}</div>}
      </form>
      <div className="copyright">
        <p>{t("Copyright © 2024 Smart Message Pioneer | Privacy Policy")}</p>
      </div>
    </div>
  );
};

export default ResetForm;
