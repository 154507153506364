import React from "react";
import MainLayout from "../../../components/Layout/Layout";
import AdminTicketManagement from "../../../components/Admin/AdminTicketManagement/AdminTicketManagement";

const AdminTicketManagementPage = () => {
  return (
    <MainLayout>
      <AdminTicketManagement />
    </MainLayout>
  );
};

export default AdminTicketManagementPage;
