import React, { useState, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import { globalApiCalling } from "../../Utils/globalApiCall";
import HelpCenterSidebar from "./HelpCenterSidebar";
import HelpSideBarSelected from "./HelpSideBarSelected";

const HelpCenterContent = ({ searchInput }) => {
  const [selectedSection, setSelectedSection] = useState(null); // Initially set to null
  const [sectionData, setSectionData] = useState([]);

  const getSectionData = async () => {
    try {
      const response = await globalApiCalling(
        "GET",
        `doublefollowers/v1/help-section/list`
      );
      const data = await response?.json();

      if (data?.code === 1000 || data?.code === 200) {
        const filteredData = data?.data?.map((item) => ({
          title: item?.Name,
          items: item?.Articles,
        }));

        setSectionData(filteredData);

        if (filteredData.length > 0) {
          setSelectedSection(filteredData[0]); // Select the first section content
        }
      }
    } catch (error) {
      console.error("Error fetching media:", error);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    getSectionData();
  }, []);

  // Filter sections based on the search input
  const filteredSections = useMemo(() => {
    if (!searchInput) return sectionData;

    const lowerSearchInput = searchInput?.toLowerCase();

    return sectionData
      .map((section) => {
        const filteredItems = section?.items?.filter((item) => {
          // Ensure item is a string before calling toLowerCase
          if (typeof item === "string") {
            return item?.toLowerCase()?.includes(lowerSearchInput);
          }
          return false; // Return false if item is not a string
        });

        if (
          filteredItems.length > 0 ||
          section?.title?.toLowerCase()?.includes(lowerSearchInput)
        ) {
          return {
            ...section,
            items: filteredItems,
          };
        }
        return null;
      })
      .filter(Boolean);
      // searchInput // remove the dependency from the memo function to disable the search
  }, [sectionData]);

  return (
    <div className="flex">
      <HelpCenterSidebar
        setSelectedSection={setSelectedSection}
        selectedSection={selectedSection}
        sectionData={filteredSections}
      />
      <div className="w-3/4 bg-white p-8 rounded-lg shadow ml-4">
        {/* Render the selected section content */}
        {selectedSection ? (
          <HelpSideBarSelected selectedSection={selectedSection} />
        ) : (
          <p>Select a section to view its content.</p>
        )}
      </div>
    </div>
  );
};

export default HelpCenterContent;
