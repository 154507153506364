import React from "react";
import MainLayout from "../../../components/Layout/Layout";
import HelpManagement from "../../../components/Admin/HelpManagement/HelpManagement";

const HelpManagementPage = () => {
  return (
    <MainLayout>
      <HelpManagement />
    </MainLayout>
  );
};

export default HelpManagementPage;
