import React, { useEffect, useState } from "react";
import SvgComponent from "../SvgComponent/SvgComponent";
import faqData, { categories } from "./faqData";
import BreadCrumbs from "../common/BreadCrumbs";
import { toast } from "react-toastify";
import { globalApiCalling } from "../../Utils/globalApiCall";

const FAQSection = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const breadcrumbItems = ["User", "FAQ"];
  const [categories, setCategories] = useState([]);
  const [faqData, setFaqData] = useState({});

  const fetchCategories = async () => {
    try {
      const response = await globalApiCalling(
        "GET",
        `doublefollowers/v1/faq-category/list?limit=&page_no=&name=`
      );
      const data = await response?.json();
      if (data?.code === 200 || data?.code === 1000) {
        const categories = data?.data?.map((item) => ({
          name: item?.Name,
          description: item?.Description,
          faqs: item?.FAQs,
        }));

        const faqData = categories?.reduce((acc, category) => {
          acc[category.name] = category?.faqs?.map((faq) => ({
            question: faq?.Question,
            answer: faq?.Answer,
          }));
          return acc;
        }, {});

        setCategories(categories || []);
        setFaqData(faqData);

        // Set the first category as selected by default
        if (categories?.length > 0) {
          setSelectedCategory(categories[0].name);
        }
      }
    } catch (error) {
      console.error("Error fetching media:", error);
      toast.error(error?.message);
    }
  };

  const handleQuestionClick = (index) => {
    setExpandedQuestion(expandedQuestion === index ? null : index);
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  console.log({ categories });
  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <BreadCrumbs items={breadcrumbItems} />
      </div>
      {categories?.length > 0 && (
        <div className="mb-4">
          <h1 className="font-bold text-[18px] ">Frequently Asked Questions</h1>
        </div>
      )}
      <div className="flex gap-[24px]">
        {/* Sidebar */}
        <div className="w-1/4">
          {categories?.length > 0 &&
            categories?.map((category, index) => (
              <div
                key={index}
                className={`p-4 mb-4 bg-white cursor-pointer rounded-[8px] ${
                  selectedCategory === category?.name
                    ? "border border-[#696CFF]"
                    : ""
                }`}
                onClick={() => {
                  setSelectedCategory(category?.name);
                  setExpandedQuestion(null); // Reset expanded question when category changes
                }}
              >
                <h3 className="text-sm font-medium">{category?.name}</h3>
                <p className="text-xs text-[#687779] mt-[5px]">
                  {category?.description}
                </p>
              </div>
            ))}
        </div>

        {/* Content Area */}
        {categories?.length > 0 && (
          <div
            className="w-3/4 bg-white p-8 rounded-[12px] "
            style={{ wordWrap: "break-word" }}
          >
            <h2 className="text-[18px] font-bold mb-4">{selectedCategory}</h2>
            <p className="text-gray-500 mb-8">
              {
                categories?.find(
                  (category) => category?.name === selectedCategory
                )?.description
              }
            </p>
            {faqData?.[selectedCategory]?.map((item, index) => (
              <div
                key={index}
                className={`p-4 mb-4 cursor-pointer  ${
                  expandedQuestion === index
                    ? " bg-[#F9F9FF] rounded-[12px] "
                    : ""
                }`}
                onClick={() => handleQuestionClick(index)}
              >
                <div className="flex justify-between items-center">
                  <h4 className="text-sm font-medium">{item.question}</h4>
                  <span>
                    {expandedQuestion === index ? (
                      <SvgComponent name={"MinusCircleIcon"} />
                    ) : (
                      <SvgComponent name={"AddCircleIcon"} />
                    )}
                  </span>
                </div>
                {expandedQuestion === index && (
                  <p className="mt-4 text-[#687779] text-sm ">{item?.answer}</p>
                )}
              </div>
            ))}
          </div>
        )}
        {categories?.length === 0 && (
          <div className="flex justify-center items-center h-64">
            <p className="text-lg font-semibold text-gray-500">
              No FAQs available at the moment.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default FAQSection;
