import { toast } from "react-toastify";

export const globalApiCalling = async (method, url, data, formData) => {
  // Check if auth_session is present in the cookies
  const authSession = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_session="));
  const keywords = ["login", "pw_change", "password", "signup", "email/sent"];

  const containsKeyword = keywords.some((keyword) => url.includes(keyword));
  if (!authSession && !containsKeyword) {
    toast.error("Session expired!");
    setTimeout(() => {
      window.location.href = "/";
    }, 2000);
    return;
    // { data: "Session expired!" };
  }
  try {
    const headers = formData ? {} : { "Content-Type": "application/json" };

    const body = formData ? data : JSON.stringify(data);

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
      method: method,
      headers: headers,
      body: body,
      credentials: "include",
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
