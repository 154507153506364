import React, { useState, useEffect } from "react";
import SvgComponent from "../SvgComponent/SvgComponent";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  entriesPerPage,
  onEntriesChange,
  setCurrentPage,
}) => {
  const [visiblePages, setVisiblePages] = useState([]);

  console.log("pages::", visiblePages);

  useEffect(() => {
    updateVisiblePages(currentPage);
  }, [currentPage, totalPages]);
  console.log(setCurrentPage, onPageChange);

  const handlePageChange = (page) => {
    // if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
    onPageChange(page);
    updateVisiblePages(page);
    // }
  };
  const handleLastPage = (page) => {
    console.log("Pag::", page);
    // if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
    onPageChange(page);
    updateVisiblePages(page);
  };

  const updateVisiblePages = (page) => {
    if (totalPages <= 3) {
      setVisiblePages(Array.from({ length: totalPages }, (_, i) => i + 1));
    } else if (page === 1) {
      setVisiblePages([1, 2, 3]);
    } else if (page === totalPages) {
      setVisiblePages([totalPages - 2, totalPages - 1, totalPages]);
    } else {
      setVisiblePages([page - 1, page, page + 1]);
    }
  };

  return (
    <div className="flex items-center justify-between pr-5 pl-8 py-4 bg-white mx-[-16px] absolute bottom-0 w-[100%]">
      <div className="flex items-center space-x-2">
        <span className="text-sm text-[#9C9C9C] font-medium">Page</span>
        <input
          type="text"
          value={currentPage}
          onChange={(e) => handlePageChange(Number(e.target.value))}
          className="w-10 px-2 py-1 border rounded text-center border-[#E2F0F1] text-[#9C9C9C]"
        />
        <span className="text-[#9C9C9C] text-sm font-medium">
          <span className="text-[#CECECE] pr-2">of</span>
          {totalPages}
        </span>
      </div>
      <div className="flex items-center space-x-2">
        {currentPage > 1 && (
          <button
            className="px-3 py-1 "
            style={{ transform: "rotate(180deg)" }}
            onClick={() => handleLastPage(visiblePages[0])}
          >
            <SvgComponent name="doubleArrow" />
          </button>
        )}
        {currentPage > 1 && (
          <button
            className="px-3 py-1"
            style={{ transform: "rotate(180deg)" }}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <SvgComponent name="paginationArrow" />
          </button>
        )}

        {visiblePages?.map((page) => (
          <button
            key={page}
            className={`px-3 py-1 rounded text-sm text-[#9C9C9C] ${
              currentPage === page ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
        {currentPage < totalPages && (
          <button
            className="px-3 py-1"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <SvgComponent name="paginationArrow" />
          </button>
        )}
        {currentPage < totalPages && (
          <button
            className="px-3 py-1"
            onClick={() => handleLastPage(visiblePages.length)}
          >
            <SvgComponent name="doubleArrow" />
          </button>
        )}
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-[#9C9C9C] text-sm font-medium">Entries</span>
        <div className="relative">
          <select
            className="px-3 py-[6px] !pl-2 w-[65px] border border-[#E2F0F1] text-sm text-[#9C9C9C] rounded bg-white appearance-none"
            value={entriesPerPage}
            onChange={(e) => onEntriesChange(Number(e.target.value))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
              <path d="M5.25 7.5L10 12.25 14.75 7.5H5.25z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
