import React from "react";
import MainLayout from "../../../components/Layout/Layout";
import FAQ from "../../../components/Admin/FAQ/FAQ";

const AdminFAQPage = () => {
  return (
    <MainLayout>
      <FAQ />
    </MainLayout>
  );
};

export default AdminFAQPage;
