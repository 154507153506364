import React, { useEffect, useState } from "react";
import TabsAndSubgroup from "./TabsAndSubgroup";
import avatar from "../../../Assets/user.png";
import SvgComponent from "../../SvgComponent/SvgComponent";
import MaterialTagsUpsert from "../../TemplateManagement/MaterialTagsUpsert";
import { TagTypes } from "../../../Utils/templateTypeEnum";
import { useFollowerContext } from "../../../Utils/Context/Context";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { globalApiCalling } from "../../../Utils/globalApiCall";

const FriendsList = () => {
  const { t } = useTranslation();
  const { setSelectedContactId } = useFollowerContext();
  const [lists, setLists] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState({
    listIndex: null,
    friendIndex: null,
  });
  const [visibleLists, setVisibleLists] = useState({});
  const [showMaterialTagUpsert, setShowMaterialTagUpsert] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [isSearchOptionsOpen, setIsSearchOptionsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchOptions = [
    {
      label: "Groups",
      value: "Groups",
    },
    {
      label: "Friends",
      value: "Friends",
    },
  ];
  const [selectedSearchOption, setSelectedSearchOption] = useState(
    searchOptions[0]
  );
  const { fetchFriendsGroup, selectedAccountID } = useFollowerContext();

  const handleSearchOptionClick = (option) => {
    setSelectedSearchOption(option);
    setIsSearchOptionsOpen(false);
  };

  const toggleListVisibility = (index) => {
    setVisibleLists((prevVisibleLists) => ({
      ...prevVisibleLists,
      [index]: !prevVisibleLists[index],
    }));
  };

  const fetchTagsAndFriends = async () => {
    try {
      // Fetch all tags first
      const tagResponse = await globalApiCalling(
        "GET",
        `doublefollowers/v1/tag/list?limit=&page_no=&name=${
          selectedSearchOption?.value === "Groups" ? searchInput : ""
        }&tag_type=${TagTypes?.CONTACTS}`
      );
      const tagData = await tagResponse?.json();
      const tags = tagData?.data || [];

      // Fetch the friend list
      const friendResponse = await globalApiCalling(
        "GET",
        `doublefollowers/v1/contact/list?limit=&page_no=1&${
          selectedSearchOption?.value === "Groups"
            ? `tag_name=${searchInput}`
            : `contact_name=${searchInput}`
        }&acc_id=${selectedAccountID}`
      );
      const friendData = await friendResponse?.json();
      if (friendData?.code === 200 || friendData?.code === 1000) {
        const friends = friendData?.data || [];

        // Group friends by tag name
        const groupedFriends = friends?.reduce((acc, item) => {
          const tagName = item?.tags?.[0]?.Name || t("Ungrouped");
          if (!acc[tagName]) {
            acc[tagName] = {
              name: tagName,
              count: 0,
              friends: [],
            };
          }
          acc[tagName].count += 1;
          acc[tagName]?.friends?.push({
            name: item?.name,
            message: `${item?.contact_number}`,
            time: new Date(item?.created_at).toLocaleTimeString(),
            avatar: avatar,
            ID: item?.id,
          });
          return acc;
        }, {});

        // Add an empty "Ungrouped" category if it doesn't exist
        if (!groupedFriends[t("Ungrouped")]) {
          groupedFriends[t("Ungrouped")] = {
            name: t("Ungrouped"),
            count: 0,
            friends: [],
          };
        }

        // Merge tags and grouped friends
        let allLists = tags?.map((tag) => ({
          name: tag?.Name,
          count: groupedFriends[tag?.Name]?.count || 0,
          friends: groupedFriends[tag?.Name]?.friends || [],
        }));

        // Add the ungrouped list to allLists
        allLists.push({
          name: t("Ungrouped"),
          count: groupedFriends[t("Ungrouped")]?.count || 0,
          friends: groupedFriends[t("Ungrouped")]?.friends || [],
        });

        if (selectedSearchOption?.value !== "Groups" && searchInput) {
          allLists = allLists.sort((a, b) => b.count - a.count);
        }
        setLists(allLists);

        // Set visibility of all lists to false initially
        setVisibleLists(
          allLists.reduce((acc, _, index) => {
            acc[index] = false;
            return acc;
          }, {})
        );
      }
    } catch (error) {
      console.error("Error fetching tags and friends:", error);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (selectedAccountID) {
      fetchTagsAndFriends();
    }
  }, [fetchFriendsGroup, selectedAccountID, searchInput]);
  useEffect(() => {
    selectedAccountID && searchInput && fetchTagsAndFriends();
  }, [selectedSearchOption]);

  return (
    <div className="flex flex-col bg-white shadow-md border-radius-12 p-4 w-full gap-3">
      <TabsAndSubgroup
        setShowMaterialTagUpsert={setShowMaterialTagUpsert}
        searchOptions={searchOptions}
        isSearchOptionsOpen={isSearchOptionsOpen}
        setIsSearchOptionsOpen={setIsSearchOptionsOpen}
        selectedSearchOption={selectedSearchOption}
        handleSearchOptionClick={handleSearchOptionClick}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
      />
      <div className="overflow-auto">
        {lists.map((list, listIndex) => (
          <div key={listIndex} className="mb-4">
            <div
              className={`border-b pt-4 cursor-pointer`}
              onClick={() => toggleListVisibility(listIndex, list?.ID)}
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center justify-content-center gap-2.5">
                  <SvgComponent name={"folder"} />
                  <h4 className="text-gray-500 font-semibold text-sm">
                    {list.name} ({list.count})
                  </h4>
                </div>
                <button className="text-gray-500">
                  <SvgComponent
                    name={visibleLists[listIndex] ? "aboveArrow" : "downArrow"}
                  />
                </button>
              </div>
            </div>
            {visibleLists[listIndex] && (
              <div className="mt-2">
                {list?.friends?.length > 0 ? (
                  list?.friends?.map((friend, friendIndex) => (
                    <div
                      key={friendIndex}
                      className={`flex items-center space-x-4 p-2 rounded-tr-lg rounded-br-lg cursor-pointer ${
                        selectedFriend.listIndex === listIndex &&
                        selectedFriend.friendIndex === friendIndex
                          ? "bg-[#ecedff] border-[2px] border-[#696cff]"
                          : "hover:bg-[#f3f5fb]"
                      }`}
                      onClick={() => {
                        setSelectedContactId(friend?.ID);
                        setSelectedFriend({ listIndex, friendIndex });
                      }}
                    >
                      <img
                        src={friend?.avatar}
                        alt={friend?.name}
                        className="h-10 w-10 rounded-full"
                      />
                      <div className="flex-grow">
                        <h5 className="text-xs font-semibold">
                          {friend?.name}
                        </h5>
                        <p className="text-gray-500 font-size-10">
                          {friend?.message}
                        </p>
                      </div>
                      <span className="text-gray-500 font-size-10">
                        {friend?.time}
                      </span>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500 font-size-10 text-center">
                    {t("No Data")}
                  </p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {showMaterialTagUpsert && (
        <MaterialTagsUpsert
          fetchTagsAndFriends={fetchTagsAndFriends}
          tagOptions={tagOptions}
          setShowMaterialTagUpsert={setShowMaterialTagUpsert}
          setLists={setLists}
          lists={lists}
          selectedSearchOption={selectedSearchOption}
          tagType={TagTypes?.CONTACTS}
          setOpenModal={setShowMaterialTagUpsert}
        />
      )}
    </div>
  );
};

export default FriendsList;
