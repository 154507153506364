import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Logo.png";
import "./ResetPw.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { globalApiCalling } from "../../Utils/globalApiCall";

const ResetPw = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleEmailSent = async (e) => {
    e.preventDefault();
    try {
      const response = await globalApiCalling(
        "POST",
        `doublefollowers/v1/send/email`,
        {
          email: email,
        }
      );
      const data = await response?.json();
      if (data?.code === 200 || data?.code === 1000) {
        navigate("/reset-pw/email-sent", { state: { email } });
      } else {
        toast.error(data?.data || "Error resetting password");
      }
    } catch (error) {
      console.error(t("Error during Log in"), error);
      toast.error(error?.message);
    }
  };

  return (
    <div className="reset-pw">
      <img src={logo} alt="Logo" className="logo" />
      <form className="form" onSubmit={handleEmailSent}>
        <h2>{t("Reset Password")}</h2>
        <div className="text-1 mt-[10px]">
          <p>
            {t("Please enter your email address to request a password reset.")}
          </p>
        </div>
        <div className="input-container">
          <label htmlFor="email">{t("Email")}</label>
          <input
            type="email"
            id="email"
            className="input"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type="submit" className="reset-button">
          {t("Reset")}
        </button>
      </form>
      <div className="copyright">
        <p>{t("Copyright © 2024 Smart Message Pioneer | Privacy Policy")}</p>
      </div>
    </div>
  );
};
export default ResetPw;
