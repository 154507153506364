import React from "react";
import MainLayout from "../../../components/Layout/Layout";
import AdminUserManagement from "../../../components/Admin/AdminUserManagement/AdminUserManagement";

const AdminUserManagementPage = () => {
  return (
    <MainLayout>
      <AdminUserManagement />
    </MainLayout>
  );
};

export default AdminUserManagementPage;
