import React from "react";
import MainLayout from "../../../components/Layout/Layout";
import CreateHelp from "../../../components/Admin/HelpManagement/CreateHelp";

const CreateHelpPage = () => {
  return (
    <MainLayout>
      <CreateHelp/>
    </MainLayout>
  );
};

export default CreateHelpPage;
